*{
  /* margin: 0;
  padding: 0; */
  outline: none;
  caret-color: transparent;
  background-repeat: no-repeat;
  font-family: "Roboto","Arial",sans-serif;
}
input,h1,h2,h3,h4,h5,p,td,th,tr{
  caret-color: black;
}
*:focus{
  outline: none;
}
